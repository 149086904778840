import dashboard from './dashboard';
import charity from './charity';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, charity]
};

export default menuItems;
