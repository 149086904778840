// assets
import { IconUsers, IconKey } from '@tabler/icons';

// constant
const icons = { IconUsers, IconKey };

const charity = {
    id: 'charity',
    type: 'group',
    title: 'Charity',
    children: [
        {
            id: 'charity-user',
            title: 'Campaign',
            type: 'item',
            url: '/charity/campaign',
            icon: icons.IconUsers
        },
        {
            id: 'charity-customer',
            title: 'Customer',
            type: 'item',
            url: '/charity/customer',
            icon: icons.IconKey,
            breadcrumbs: true
        }
    ]
};

export default charity;
