// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Axios Imports

import { axiosInstance } from 'utils/axios';

const initialState = {
    campaigns: [],
    campaign: {},
    isLoading: false,
    isCampaignLoading: false,
    error: null, // Initialize error state
    pageSize: 0,
    pageNumber: 0,
    totalCount: 0
};

// ** Fetch Campaigns
export const fetchCampaigns = createAsyncThunk(
    'campaign/fetchCampaigns',
    async ({ PageNumber, PageSize, OrganizationName, CategoryName, ProgressStatusName, SearchValue }) => {
        const res = await axiosInstance.post('/Campaign/GetCampaignsByOrganization', {
            PageNumber,
            PageSize,
            OrganizationName,
            CategoryName,
            ProgressStatusName,
            SearchValue
        });

        return res.data;
    }
);

// ** Fetch Campaign
export const fetchCampaign = createAsyncThunk('campaign/fetchCampaign', async ({ CampaignOid }) => {
    const res = await axiosInstance.post('/Campaign/GetCampaignByOid', {
        CampaignOid
    });

    return res.data;
});

// ** Create Slice
export const appCampaignSlice = createSlice({
    name: 'campaign',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // ** Handle fetchCampaign pending
            .addCase(fetchCampaign.pending, (state) => {
                state.isLoading = true;
                state.error = null; // Reset error on new request
            })
            // ** Handle fetchCampaign fulfilled
            .addCase(fetchCampaign.fulfilled, (state, action) => {
                state.isLoading = false;
                state.campaign = action.payload?.data?.campaign[0];
            })
            // ** Handle fetchCampaign rejected
            .addCase(fetchCampaign.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message || 'Failed to fetch campaign';
            })

            // ** Handle fetchCampaigns pending
            .addCase(fetchCampaigns.pending, (state) => {
                state.isCampaignLoading = true;
                state.error = null; // Reset error on new request
            })
            // ** Handle fetchCampaigns fulfilled
            .addCase(fetchCampaigns.fulfilled, (state, action) => {
                state.isCampaignLoading = false;
                state.campaigns = action.payload.data.campaigns;
                state.pageSize = action.payload.data.metadata.pageSize;
                state.pageNumber = action.payload.data.metadata.pageNumber;
                state.totalCount = action.payload.data.metadata.totalCount;
            })
            // ** Handle fetchCampaigns rejected
            .addCase(fetchCampaigns.rejected, (state, action) => {
                state.isCampaignLoading = false;
                state.error = action.error.message || 'Failed to fetch campaigns';
            });
    }
});

// ** Selector to get the campaign state
export const responseCampaign = (state) => state.campaign;
export default appCampaignSlice.reducer;
