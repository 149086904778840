import { axiosInstance } from 'utils/axios';

export async function signIn(email, password) {
    try {
        const { data } = await axiosInstance.post('/login', {
            Email: email,
            Password: password
        });
        const token = data?.data?.accessToken;
        if (!token) {
            return {
                isOk: false,
                message: 'Authentication failed'
            };
        }
        localStorage.setItem('token', token);
        return {
            isOk: true,
            data: token
        };
    } catch {
        return {
            isOk: false,
            message: 'Authentication failed'
        };
    }
}

export async function getUser() {
    try {
        // Send request

        return {
            isOk: true,
            data: localStorage.getItem('token')
        };
    } catch {
        return {
            isOk: false
        };
    }
}

export async function createAccount(email, password) {
    try {
        // Send request
        console.log(email, password);

        return {
            isOk: true
        };
    } catch {
        return {
            isOk: false,
            message: 'Failed to create account'
        };
    }
}

export async function changePassword(email, recoveryCode) {
    try {
        // Send request
        console.log(email, recoveryCode);

        return {
            isOk: true
        };
    } catch {
        return {
            isOk: false,
            message: 'Failed to change password'
        };
    }
}

export async function resetPassword(email) {
    try {
        // Send request
        console.log(email);

        return {
            isOk: true
        };
    } catch {
        return {
            isOk: false,
            message: 'Failed to reset password'
        };
    }
}
