import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/index')));

// utilities routing
const PermissionRole = Loadable(lazy(() => import('views/permission/role/Role')));
const PermissionUser = Loadable(lazy(() => import('views/permission/user/User')));

// charity routing
const Campaign = Loadable(lazy(() => import('views/pages/charity/campaign/Campaign')));
const CampaignDetail = Loadable(lazy(() => import('views/pages/charity/campaign/CampaignDetail')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: '/dashboard/default',
            element: <DashboardDefault />
        },
        {
            path: '/permission/role',
            element: <PermissionRole />
        },
        {
            path: '/permission/user',
            element: <PermissionUser />
        },
        {
            path: '/charity/campaign',
            element: <Campaign />
        },
        {
            path: '/charity/campaign/:slug',
            element: <CampaignDetail />
        }
    ]
};

export default MainRoutes;
