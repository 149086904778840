import axios from 'axios';

const axiosInstance = axios.create({
    // Add your custom Axios configuration here
    baseURL: 'https://api-charity.uosvn.com/odata',
    timeout: 5000,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + window.localStorage.getItem('token')
    },
    transformRequest: [
        (data) => {
            // Customize request data or headers if needed
            return JSON.stringify(data);
        }
    ]
});
axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        return Promise.reject((error.response && error.response.data) || 'Wrong Services');
    }
);
axiosInstance.interceptors.request.use((config) => {
    config.headers = {
        ...config.headers,
        Authorization: 'Bearer ' + window.localStorage.getItem('token')
    };

    return config;
});

export { axiosInstance };
