import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import { roleReducer, userReducer } from './permission';
import { campaignReducer } from './charity';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    user: userReducer,
    role: roleReducer,
    campaign: campaignReducer
});

export default reducer;
